// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-admin-en-tsx": () => import("./../src/pages/admin/en.tsx" /* webpackChunkName: "component---src-pages-admin-en-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-dse-de-tsx": () => import("./../src/pages/dse-de.tsx" /* webpackChunkName: "component---src-pages-dse-de-tsx" */),
  "component---src-pages-dse-en-tsx": () => import("./../src/pages/dse-en.tsx" /* webpackChunkName: "component---src-pages-dse-en-tsx" */),
  "component---src-pages-en-tsx": () => import("./../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-imp-de-tsx": () => import("./../src/pages/imp-de.tsx" /* webpackChunkName: "component---src-pages-imp-de-tsx" */),
  "component---src-pages-imp-en-tsx": () => import("./../src/pages/imp-en.tsx" /* webpackChunkName: "component---src-pages-imp-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

